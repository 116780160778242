import React from "react";
import PopupInput from "./PopupInput";
import {URL_RULES} from "../../../../../properties";
import PopupPasswordInput from "./PopupPasswordInput";
import PopupCheckbox from "./PopupCheckbox";
import {fieldErrorValue} from "../../../../../utils/errorUtils";
import useEnterKeyDown from "../../../../../hooks/useEnterKeyDown";

export default function PopupRegistrationForm({onTextTyping, toggleChecked, values, fieldErrors, register}) {

   useEnterKeyDown(register);

    return (
        <form id="registration-form" style={{display: "block"}}>
            <h1></h1>
            <PopupInput type="text" name="name" placeholder="Имя пользователя" onTextTyping={onTextTyping}
                        value={values["name"]} autoFocus={true}  errorMsg={fieldErrorValue(fieldErrors, "name")}/>
            <PopupInput type="text" name="telegram" placeholder="Telegram" onTextTyping={onTextTyping} value={values["telegram"]}
                        errorMsg={fieldErrorValue(fieldErrors, "telegram")}/>
            <PopupInput type="email" name="email" placeholder="E-mail" onTextTyping={onTextTyping} value={values["email"]}
                        errorMsg={fieldErrorValue(fieldErrors, "email")}/>
            <PopupPasswordInput onTextTyping={onTextTyping} value={values["password"]} errorMsg={fieldErrorValue(fieldErrors, "password")}
                                useAutocomplete={false}/>
            <PopupCheckbox onChange={toggleChecked} className="checkLogin" name="isTermsConfirmed" checked={values["isTermsConfirmed"]}
                           label={<>Я согласен с <a href={URL_RULES} target="_blank" rel="noopener noreferrer"> Условиями</a></>}
                           errorMsg={fieldErrorValue(fieldErrors, "isTermsConfirmed")}
            />
            <a href="#" className="login-btn" id="signup-btn" onClick={register}>Создать аккаунт</a>
        </form>
    )
}