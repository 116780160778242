import React, {Fragment} from "react";
import PopupInput from "./PopupInput";
import {fieldErrorValue} from "../../../../../utils/errorUtils";
import PopupOtherOptions from "./PopupOtherOptions";
import PopupCheckbox from "./PopupCheckbox";
import PopupPasswordInput from "./PopupPasswordInput";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {CircularProgress} from "@material-ui/core";
import useEnterKeyDown from "../../../../../hooks/useEnterKeyDown";


export default function PopupLoginForm({onTextTyping, toggleChecked, openPopup, values, fieldErrors, signIn, resendConfirmationEmail}) {

    const { executeRecaptcha } = useGoogleReCaptcha();

    executeRecaptcha && useEnterKeyDown(() => login());

    const login = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        let token;

        try {
            token = await executeRecaptcha('login');
        } catch (e) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        signIn(token);
    };


    const getResendLink = () => {
        return <><br/><a href="#" onClick={() => resendConfirmationEmail(values.login)}>Отправить повторно?</a></>
    };

    const getFieldError = () => {
        const errorMsg = fieldErrorValue(fieldErrors, "login");
        return errorMsg
            ? errorMsg.includes("перейдите по ссылке, отправленной вам на почту")
                ? <span>{errorMsg}{getResendLink()}</span>
                : errorMsg
            : null
    };

    return (
        <Fragment>
            <form id="login-form" style={{display: "block"}}>
                <PopupInput type="text" name="login" placeholder="E-mail" onTextTyping={onTextTyping} value={values["login"]} autoFocus={true}
                            errorMsg={getFieldError()} autoComplete="off"/>
                <PopupPasswordInput onTextTyping={onTextTyping} value={values["password"]} errorMsg={fieldErrorValue(fieldErrors, "password")}
                                    useAutocomplete={true}/>
                <PopupCheckbox onChange={toggleChecked} className="checkLogin" name="rememberMe" checked={values["rememberMe"]}
                               label={<>Запомнить меня</>}
                               errorMsg={fieldErrorValue(fieldErrors, "rememberMe")}/>
                {executeRecaptcha
                    ? <a href="#" className="login-btn" onClick={login}>Войти</a>
                    : <div className="login-btn d-flex justify-content-center">
                        <CircularProgress style={{color: '#fff', height:'24px', width: '24px'}}/>
                    </div>
                }
            </form>
            <PopupOtherOptions openPopup={openPopup}/>
        </Fragment>
    )
}