import { useEffect } from "react";

const useEnterKeyDown = (callback) => {
    const handleClick = e => {
        const keyCode = e.keyCode;
        if (keyCode !== 13) {
            return;
        }
        callback();
    };

    useEffect(() => {
        document.addEventListener("keydown", handleClick);

        return () => {
            document.removeEventListener("keydown", handleClick);
        };
    });
};

export default useEnterKeyDown;