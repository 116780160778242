import {
    isAdmin,
    isPartner,
    isAdminAsPartner,
    isAuthenticated, isSeniorArbitr
} from "../../utils/authUtils"

import {
    USER_AUTHENTICATED,
    CHECKING_AUTHENTICATION,
    AUTHENTICATION_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FROM_PARTNER,
    REGISTER_SUCCESS,
    REGISTER_FAIL, PASSWORD_CHANGED, CHECK_RESTORE_PASSWORD_CODE, CONFIRMATION_EMAIL_RESENT, LOGIN_AS_PARTNER
} from '../../actions/actionTypes';

const initialState = {
    user: localStorage.getItem('user'),
    isAuthenticated: isAuthenticated(),
    isAdmin: isAdmin(),
    isAdminAsPartner: isAdminAsPartner(),
    isPartner: isPartner(),
    isSeniorArbitr: isSeniorArbitr(),
    isLoading: false,
    isRestorePasswordCodeValid: true
};

export default function(state = initialState, action) {

    switch (action.type) {
        case CHECKING_AUTHENTICATION:
            return {
                ...state,
                isLoading: true
            };
        case USER_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: true,
                isAdmin: isAdmin(),
                isAdminAsPartner: isAdminAsPartner(),
                isPartner: isPartner(),
                isSeniorArbitr: isSeniorArbitr(),
                isLoading: false
            };
        case LOGIN_SUCCESS:
        case PASSWORD_CHANGED:
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isAdmin: isAdmin(),
                isAdminAsPartner: isAdminAsPartner(),
                isSeniorArbitr: isSeniorArbitr(),
                isPartner: isPartner(),
                isLoading: false
            };
        case CHECK_RESTORE_PASSWORD_CODE:
            return {
                ...state,
                isRestorePasswordCodeValid: action.payload
            };
        case REGISTER_SUCCESS:
        case CONFIRMATION_EMAIL_RESENT:
            return {
                ...state,
            };
        case LOGIN_AS_PARTNER:
            return {
                ...state,
                isAdmin: true,
                isAdminAsPartner: true,
                isPartner: false
            };
        case LOGOUT_FROM_PARTNER:
            return {
                ...state,
                isAdmin: true,
                isAdminAsPartner: false,
                isPartner: false
            };
        case AUTHENTICATION_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REGISTER_FAIL:
            localStorage.removeItem('user');
            return {
                ...state,
                user: null,
                isAuthenticated: false,
                isAdmin: false,
                isAdminAsPartner:false,
                isPartner: false,
                isSeniorArbitr: false,
                isLoading: false
            };
        default:
            return state;
    }
}